<template>
  <div class="document-file">
    <div class="document-file__main">
      <validation-provider rules="required" v-slot="{ errors }">
        <v-text-field
          ref="firstInput"
          outlined
          type="date"
          dense
          v-model="innerValue.startDate"
          placeholder="ДД.ММ.ГГГГ"
          :error-messages="errors"
          label="От"
          hide-details
          style="max-width: 140px"
        />
      </validation-provider>

      <validation-provider rules="required" v-slot="{ errors }">
        <v-text-field
          outlined
          dense
          v-model="innerValue.endDate"
          type="date"
          placeholder="ДД.ММ.ГГГГ"
          label="До"
          hide-details
          :error-messages="errors"
          style="max-width: 140px"
        />
      </validation-provider>

      <v-btn depressed fab x-small @click="remove">
        <v-icon small>fa-trash</v-icon>
      </v-btn>
    </div>

    <div class="mt-2">
      <a :href="file.file.url" target="_blank">
        {{ file.file.url }}
      </a>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      innerValue: this.file,
    }
  },
  watch: {
    innerValue: {
      deep: true,
      handler (innerValue) {
        this.$emit('input', innerValue)
      }
    }
  },
  methods: {
    focus () {
      this.$refs.firstInput.focus()
    },
    remove () {
      if (!confirm('Удалить файл?')) return
      this.$emit('remove')
    }
  }
}
</script>

<style lang="scss" scoped>
  .document-file__main {
    display: flex;
    grid-gap: 12px;
  }
</style>
