var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var handleSubmit = ref.handleSubmit;
return [_c('item-edit-view',{attrs:{"title":"Редактирование документа","loading":_vm.loading},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('v-btn',{attrs:{"right":"","color":"success","loading":_vm.saving},on:{"click":function($event){return handleSubmit(_vm.save)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-save")]),_vm._v(" Сохранить ")],1)]},proxy:true}],null,true)},[(_vm.item)?_c('v-card',[_c('v-card-text',[_c('validation-provider',{attrs:{"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('locale-field',{staticClass:"mb-8",attrs:{"component":"v-text-field","name":"name","outlined":"","autofocus":"","hide-details":"","dense":"","error-messages":errors,"label":"Название"},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})]}}],null,true)}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","block":"","label":"Код","name":"code","outlined":"","error-messages":errors},model:{value:(_vm.item.code),callback:function ($$v) {_vm.$set(_vm.item, "code", $$v)},expression:"item.code"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('document-type-select',{attrs:{"dense":"","block":"","label":"Тип","outlined":"","error-messages":errors},model:{value:(_vm.item.type),callback:function ($$v) {_vm.$set(_vm.item, "type", $$v)},expression:"item.type"}})]}}],null,true)})],1)],1),_c('v-autocomplete',{attrs:{"dense":"","outlined":"","label":"Клиники","item-text":"name","item-value":"id","items":_vm.clinics.data,"multiple":"","loading":_vm.clinics.loading},model:{value:(_vm.item.clinicIds),callback:function ($$v) {_vm.$set(_vm.item, "clinicIds", $$v)},expression:"item.clinicIds"}}),_c('div',{staticClass:"mb-8"},[_c('document-tags',{model:{value:(_vm.item.tags),callback:function ($$v) {_vm.$set(_vm.item, "tags", $$v)},expression:"item.tags"}})],1),_c('div',{staticClass:"mt-14"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){_vm.modal = true}}},[_vm._v("Добавить файл")])],1),_c('v-dialog',{attrs:{"max-width":"960"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('FilesList',{attrs:{"mode":"select"},on:{"select":_vm.onFileSelect}})],1),(_vm.item.fileItems.length)?_c('div',{staticClass:"document__files mt-6"},[_c('h4',{staticClass:"mb-4"},[_vm._v("Версии")]),_vm._l((_vm.item.fileItems),function(file,idx){return _c('DocumentFile',{key:file.file.id,ref:"file",refInFor:true,attrs:{"file":file},on:{"input":function($event){return _vm.updateFile(idx, $event)},"remove":function($event){return _vm.removeFile(idx)}}})})],2):_vm._e()],1)],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }