<template>
  <validation-observer
    v-slot="{ errors, handleSubmit }"
  >
    <item-edit-view
      title="Редактирование документа"
      :loading="loading"
    >
      <template v-slot:right>
        <v-btn
          right
          @click="handleSubmit(save)"
          color="success"
          :loading="saving"
        >
          <v-icon small>mdi-content-save</v-icon>
          Сохранить
        </v-btn>
      </template>
      <v-card v-if="item">
        <v-card-text>

            <validation-provider rules="required" v-slot="{ errors }" slim>
              <locale-field
                component="v-text-field"
                v-model="item.name"
                name="name"
                outlined
                autofocus
                hide-details
                dense
                :error-messages="errors"
                label="Название"
                class="mb-8"
              />
            </validation-provider>

            <v-row>
              <v-col cols="6">
                <validation-provider slim rules="required" v-slot="{ errors }">
                  <v-text-field
                    dense
                    block
                    label="Код"
                    v-model="item.code"
                    name="code"
                    outlined
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="6">
                <validation-provider slim rules="required" v-slot="{ errors }">
                  <document-type-select
                    dense
                    block
                    label="Тип"
                    outlined
                    v-model="item.type"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-autocomplete
              dense
              outlined
              label="Клиники"
              item-text="name"
              item-value="id"
              v-model="item.clinicIds"
              :items="clinics.data"
              multiple
              :loading="clinics.loading"
            />

            <div class="mb-8">
              <document-tags v-model="item.tags" />
            </div>

            <div class="mt-14">
              <v-btn color="success" @click="modal = true">Добавить файл</v-btn>
            </div>

            <v-dialog max-width="960" v-model="modal">
              <FilesList
                mode="select"
                @select="onFileSelect"
              />
            </v-dialog>

            <div class="document__files mt-6" v-if="item.fileItems.length">
              <h4 class="mb-4">Версии</h4>

              <DocumentFile
                ref="file"
                v-for="(file, idx) in item.fileItems"
                :key="file.file.id"
                :file="file"
                @input="updateFile(idx, $event)"
                @remove="removeFile(idx)"
              />
            </div>

            <!-- <validation-provider
              rules="required"
              name="Файлы"
              v-slot="{ errors }"
            >
              <div v-if="errors.length">
                <input v-model="item.fileItems">
                <div class="red--text">необходимо добавить файлы</div>
              </div>
            </validation-provider> -->

        </v-card-text>
      </v-card>

    </item-edit-view>
  </validation-observer>
</template>

<script>
import clinicsService from '@/services/clinics'
import documentsService from '@/services/documents'
import filesService from '@/services/files'
import createOrUpdateViewMixin from '../../mixins/createOrUpdateView'
import DocumentFile from './DocumentFile.vue'
import FilesList from '../../components/Files/FilesList.vue'
import DocumentTags from './DocumentTags.vue'
import DocumentTypeSelect from './DocumentTypeSelect.vue'
import LocaleField from '@/components/Locale/LocaleField.vue'

export default {
  mixins: [createOrUpdateViewMixin(documentsService)],
  components: {
    LocaleField,
    DocumentTypeSelect,
    DocumentFile,
    FilesList,
    DocumentTags
  },
  data () {
    return {
      clinics: {
        loading: false,
        data: []
      },
      modal: false,
      date: null,
    }
  },
  mounted () {
    this.loadClinics()
  },
  methods: {
    async loadClinics () {
      this.clinics.loading = true
      try {
        const { items } = await clinicsService.getAll()
        this.clinics.data = items
      } catch (e) {
        console.error(e)
      }
      this.clinics.loading = false
    },
    onFileSelect (file) {
      this.modal = false
      this.item.fileItems.unshift({
        file,
      })
      this.$nextTick(() => {
        const lastEl = this.$refs.file[this.item.fileItems.length - 1]
        lastEl.focus()
      })
    },
    async removeFile (idx) {
      const { file } = this.item.fileItems[idx]
      await filesService.remove(file.id)
      this.$delete(this.item.fileItems, idx)
    },
    updateFile (idx, file) {
      this.$set(this.item.fileItems, idx, file)
    }
  }
}
</script>

<style lang="scss" scoped>
.document__files {
  & > * {
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
