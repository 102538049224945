<template>
  <v-autocomplete v-bind="attrs" v-on="$listeners" />
</template>

<script>
import documentsService from '@/services/documents'

export default {
  computed: {
    attrs () {
      return {
        name: 'type',
        itemText: 'name',
        itemValue: 'type',
        items: documentsService.types,
        ...this.$attrs,
      }
    },
  }
}
</script>
